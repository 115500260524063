import { Attr, Model, HasMany } from 'spraypaint';

import { ApplicationRecord } from './ApplicationRecord';
import { Flag } from './Flag';

@Model()
export class Compliance extends ApplicationRecord {
  public static jsonapiType = 'compliances';

  @Attr() public complianceableId: string;

  @Attr() public tacticalAllocationEnabled: boolean;
  @Attr() public tacticalAllocationActionPointsEnabled: boolean;
  @Attr() public cashAndFixedIncomeLocked: boolean;
  @Attr() public useDefault: boolean;
  @Attr() public countryOfDomicileInheritFromClient: boolean;
  @Attr() public countryOfRiskInheritFromClient: boolean;
  @Attr() public instrumentInheritFromClient: boolean;
  @Attr() public industryInheritFromClient: boolean;
  @Attr() public currencyInheritFromClient: boolean;

  @HasMany(Flag) public flags: Flag[];
}
