import { Attr, HasMany, BelongsTo, Model, HasOne } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';

import { Administrator } from './Administrator';
import { ClientUserRole } from './roles/ClientUser';
import { Flag } from './Flag';
import { Note } from './Note';
import { User } from './User';
import { ClientLetterOfEngagement } from './ClientLetterOfEngagement';
import { Compliance } from './Compliance';

@Model()
export class ExtraSettingsType extends ApplicationRecord {
  public static jsonapiType = 'extra_settings_types';
  @Attr() public companyId: string;
  @Attr() public settingType: string;
}

@Model()
export class Client extends ApplicationRecord {
  public static jsonapiType = 'clients';
  @Attr() public name: string;
  @Attr() public emapFee: number;
  @Attr() public consolidatedFeeTier1: number;
  @Attr() public bookkeepingFee: number;
  @Attr() public consolidatedFeeTier3: number;
  @Attr() public combinedFee: number;
  @Attr() public combinedComponentFee: number;
  @Attr() public quantOnlyFee: number;
  @Attr() public feeCurrency: string;
  @Attr() public reference: string;
  @Attr() public showPath: string;
  @Attr() public userCount: number;
  @Attr() public portfolioCount: number;
  @Attr() public incompletePortfolioReviewCount: number;
  @Attr() public completedPortfolioReviewCount: number;
  @Attr() public monitoringInvoiceableReviewCount: number;
  @Attr() public monitoringInvoiceableReviewTotalCount: number;
  @Attr() public xeroContactId: string;
  @Attr() public xeroTenantId: string;
  @Attr() public allocationCommentary: boolean;
  @Attr() public holdingCommentary: boolean;
  @Attr() public performanceCommentary: boolean;
  @Attr() public ratingCommentary: boolean;
  @Attr() public clientType: string;
  @Attr() public clientTypeIcon: string;
  @Attr() public childClientIds: string[];
  @Attr() public monitoringRelationshipManagerId: string;
  @Attr() public advisoryRelationshipManagerId: string;
  @Attr() public managesActionPointsInPortal: boolean;
  @Attr() public additionalPortfolioAttributes: string[];

  // Currently don't exist in the resource
  @Attr() public autosendReviews: boolean;
  @Attr() public jurisdiction: string;
  @Attr() public region: string;

  @HasMany() public users: User[];
  @HasMany() public extraSettingsTypes: ExtraSettingsType[];
  @HasMany() public flags: Flag[];
  @HasMany() public notes: Note[];
  @HasMany() public clientUserRoles: ClientUserRole[];
  @HasMany() public roles: ClientUserRole[];
  @HasMany() public documents: ClientLetterOfEngagement[];

  @HasOne(Compliance) public compliance: Compliance;

  @BelongsTo(Administrator) public relationshipManager: Administrator;
  @BelongsTo(Administrator) public monitoringRelationshipManager: Administrator;
  @BelongsTo(Administrator) public advisoryRelationshipManager: Administrator;
}
